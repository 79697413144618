<template>
  <b-overlay :show="isLoading" rounded="lg" opacity="0.6" spinner-variant="primary">
    <template #overlay>
      <div class="d-flex align-items-center">
        <b-spinner small type="grow" variant="secondary"></b-spinner>
        <b-spinner type="grow" variant="dark"></b-spinner>
        <b-spinner small type="grow" variant="secondary"></b-spinner>
      </div>
    </template>
    <div class="py-4">
      <b-card>
        <b-row>
          <b-col lg="6">
            <input class="form-control" placeholder="Type to Search" type="search" @input="actionSearch"
              v-on:keyup.enter="actionSearch" />
            <span v-if="typing"> <em> typing .. </em> </span>
          </b-col>
        </b-row>
        <div class="py-2 text-right">
          <span class="pr-2">
            <router-link class="btn btn-sm btn-dark" :to="{ name: AddUserVendorPath.name }">
              <i class="fa fa-plus"></i>
            </router-link>
          </span>
          <span>
            <button class="btn btn-sm btn-dark" @click="actionGetUser">
              <i class="fa fa-refresh"></i>
            </button>
          </span>
          <span class="" style="vertical-align: bottom;">
            Count <strong>{{ totalRows }}</strong>
          </span>
        </div>
        <b-row>
          <b-col>
            <div style="white-space: nowrap;">
              <b-table sticky-header="600px" responsive="xl" :tbody-tr-class="rowClass" show-empty :busy="isLoading"
                hover :fields="fields" :items="userVendor">
                <template #cell(username)="row">
                  <div class="p-auto">
                    <router-link :to="{ name: EditUserVendorPath.name, params: { id: row.item.id } }">
                      {{ row.item.username }}
                      <b-badge variant="primary" v-if="row.item.is_active">Active</b-badge>
                      <b-badge variant="danger" v-else>InActive</b-badge>
                    </router-link>
                  </div>
                </template>
              </b-table>
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="limit" aria-controls="my-table" />
          </b-col>
        </b-row>
      </b-card>
    </div>
  </b-overlay>
</template>

<script>
import moment from 'moment';
import { mapActions, mapState } from 'vuex';
import { AddUserVendorPath, EditUserVendorPath } from '../../../router/settings';
const ROLE = {
  5: 'Admin',
  6: 'Marketing',
  7: 'Finance',
  19: 'Banned Account',
};
export default {
  data() {
    return {
      query_search: null,
      limit: 50,
      currentPage: 1,
      typing: false,
      fields: [
        {
          key: 'username',
          sortable: true
        },
        { key: 'email' },
        {
          label: 'Role',
          key: 'role_id',
          formatter: (val) => ROLE[val],
        },
        { key: 'vendor_id' },
        {
          key: 'createdAt',
          label: 'Created',
          formatter: (val) => moment(val).format('DD MMM YYYY'),
        },
        {
          key: 'updatedAt',
          label: 'Updated',
          formatter: (val) => moment(val).format('DD MMM YYYY'),
        },
      ],
      AddUserVendorPath,
      EditUserVendorPath,
    }
  },
  mounted() {
    this.actionGetUser();
  },
  watch: {
    currentPage: function () {
      this.actionGetUser();
    },
  },
  computed: {
    ...mapState({
      isLoading: (state) => state.admin.isLoading,
      isError: (state) => state.admin.isError,
      userVendor: (state) => state.admin.items,
      totalRows: (state) => state.admin.totalRows,
    }),
  },
  methods: {
    ...mapActions('admin', ['fetchAdminVendor']),
    actionGetUser() {
      this.fetchAdminVendor({
        q: this.query_search,
        limit: this.limit,
        page: this.currentPage,
      });
    },
    actionSearch(event) {
      const val = event.target.value;
      this.query_search = val;
      this.currentPage = 1;
      if (!val.trim()) {
        this.actionGetUser();
        return;
      }
      this.typing = true;
      this.isLoadingSearch = true;
      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        this.typing = false;
        this.actionGetUser();
      }, 600); this
    },

    rowClass(item) {
      if (!item) return;
      if (item?.is_active !== true) return 'table-danger';
    }
  },
}
</script>

<style scoped>

</style>